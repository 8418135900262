import pageElementWithTransition from './src/utils/wrapPageElement'
import wrapRootElementWithProvider from './src/utils/wrapRootElement'

import './src/styles/fonts.css'
import './src/styles/reset.css'

export const wrapRootElement = wrapRootElementWithProvider
export const wrapPageElement = pageElementWithTransition

export const onRouteUpdate = ({ location, prevLocation }) => {
	if (location && location.state)
		location.state.referrer = prevLocation ? prevLocation.pathname : null
}

// Automatically refresh when content has been updated
// export function onServiceWorkerUpdateReady() {
// 	window.location.reload(true)
// }
