import * as React from 'react'

function Upwards(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M4.75 10.73l4.089-3.98 3.911 3.98M8.839 6.75v9H19.25"
				stroke="currentColor"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			/>
		</svg>
	)
}

const MemoUpwards = React.memo(Upwards)
export default MemoUpwards
