import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { IconArrowLeft, IconArrowRight } from './Icons'

const Wrapper = styled.div`
	display: ${p => (p.hide ? 'none' : 'flex')};
	max-width: ${props => props.theme.maxWidths.general};
	margin: 0 auto;
	padding: 8rem 0 4rem 0;
	a {
		color: var(--color-ink);
		display: flex;
		align-items: center;
		font-size: var(--fs-s);

		@media (min-width: ${props => props.theme.breakpoints.xs}) {
			font-size: var(--fs-m);
		}

		@media (min-width: ${props => props.theme.breakpoints.s}) {
			font-size: var(--fs-l);
		}
		&:hover {
			color: var(--color-linkHover);
		}
	}
	justify-items: center;
`

const Prev = styled.div`
	svg {
		width: 1.5em;
		height: 1.5em;
		margin: 0 1rem 0 0;
		transition: transform ease-out 0.3s;
	}
	&:hover svg {
		transform: translateX(-0.25em) scale(0.8);
	}
	padding-right: 1em;
`

const Next = styled.div`
	text-align: right;
	svg {
		width: 1.5em;
		height: 1.5em;
		margin: 0 0 0 1rem;
		transition: transform ease-out 0.3s;
	}
	&:hover svg {
		transform: translateX(0.25em) scale(0.8);
	}
	margin-left: auto;
	padding-left: 1em;
`

const ProjectPagination = ({ next, prev, nextAll, prevAll, archive }) => (
	<>
		<Wrapper hide={archive}>
			{prev && (
				<Prev>
					<Link
						className="no-underline"
						to={prev.fields.slug}
						aria-label="Previous"
					>
						<IconArrowLeft />
						{prev.frontmatter.title}
					</Link>
				</Prev>
			)}

			{next && (
				<Next>
					<Link
						className="no-underline"
						to={next.fields.slug}
						aria-label="Next"
					>
						{next.frontmatter.title}
						<IconArrowRight />
					</Link>
				</Next>
			)}
		</Wrapper>
		<Wrapper hide={!archive}>
			{prevAll && (
				<Prev>
					<Link
						className="no-underline"
						to={prevAll.fields.slug + '?archive'}
						aria-label="Previous"
					>
						<IconArrowLeft />
						{prevAll.frontmatter.title}
					</Link>
				</Prev>
			)}

			{nextAll && (
				<Next>
					<Link
						className="no-underline"
						to={nextAll.fields.slug + '?archive'}
						aria-label="Next"
					>
						{nextAll.frontmatter.title}
						<IconArrowRight />
					</Link>
				</Next>
			)}
		</Wrapper>
	</>
)

export default ProjectPagination

ProjectPagination.propTypes = {
	next: PropTypes.object,
	prev: PropTypes.object,
	nextAll: PropTypes.object,
	prevAll: PropTypes.object,
	archive: PropTypes.bool,
}

ProjectPagination.defaultProps = {
	next: null,
	prev: null,
	alleNext: null,
	prevAll: null,
	archive: false,
}
