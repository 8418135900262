import {
	ContentWrapper,
	ContentGrid,
	ContentTextImage,
	ContentGroup,
	//VideoPlayer,
	ContentImageListItem,
	ContentList,
	ListItem,
	ContentProcessGraph,
} from '../components/'

const shortcodes = {
	Wrapper: ContentWrapper,
	Grid: ContentGrid,
	TextImage: ContentTextImage,
	Group: ContentGroup,
	//VideoPlayer: VideoPlayer,
	ImageListItem: ContentImageListItem,
	ul: ContentList,
	li: ListItem,
	ContentList: ContentList,
	ListItem: ListItem,
	ProcessGraph: ContentProcessGraph,
}

export default shortcodes
