import React from 'react'
import styled from '@emotion/styled'

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: ${props =>
		props.vertical ? 'repeat(auto-fill, minmax(22ch, 1fr))' : undefined};
	grid-gap: 0;
`

const StyledListItem = styled.li`
	display: grid;
	grid-template-columns: 0 1fr;
	grid-gap: 1.75em;
	align-items: start;
	line-height: 1.5;

	margin-bottom: 0.4em;

	&:before {
		content: '';
		font-size: 1em;
		mask: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='square' d='M0.5,12 L23.5,12' transform='matrix(1 0 0 -1 0 24)'/%3E%3C/svg%3E%0A")
			center/cover no-repeat`};
		width: 1em;
		height: 1em;
		margin: 0.35em 0 0 0;
		background: var(--color-ink);
	}
`

export function ListItem({ children, ...rest }) {
	return (
		<StyledListItem {...rest}>
			<span>{children}</span>
		</StyledListItem>
	)
}

export default function ContentList({ children, vertical, ...rest }) {
	return (
		<StyledList role="list" vertical={vertical} {...rest}>
			{children}
		</StyledList>
	)
}
