import React from 'react'

function ArrowLeft(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M6.98 16L3 11.911 6.98 8M3 11.911h18"
				stroke="currentColor"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			/>
		</svg>
	)
}

const MemoArrowLeft = React.memo(ArrowLeft)
export default MemoArrowLeft
