/* eslint react/prop-types: 0 */
import React from 'react'
import { Transition, NavigationBar, ContactModal } from '../components/'
import { myContext } from './provider'
import styled from '@emotion/styled'

const BackgroundWrapper = styled.div`
	position: fixed;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	min-height: 100vh;
	background: var(--color-bg, #141414);
	/* transition: background-color 0.3s linear; */
`

const wrapPageElement = ({ element, props }) => {
	let currentPath = props.path?.replace(/\//g, '')
	let currentParent = props.pageContext.collection || currentPath || ''
	let currentLocation = currentParent

	switch (currentParent) {
		case 'featured':
			currentLocation = 'projects'
			break
		case 'side':
			currentLocation = 'blog'
			break
		default:
			break
	}

	return (
		<myContext.Consumer>
			{context => (
				<>
					<NavigationBar
						triggerContactModal={() => context.openContactModal()}
						contactModal={context.contactModal}
						current={currentLocation}
					/>
					<BackgroundWrapper />
					<Transition {...props}>{element}</Transition>

					<ContactModal
						render={context.renderContactModal}
						visible={context.contactModal}
						copied={context.mailCopied}
						closeHandler={context.closeContactModal}
						copyHandler={context.mailCopyHandler}
					/>
				</>
			)}
		</myContext.Consumer>
	)
}

export default wrapPageElement
