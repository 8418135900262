import React, { useEffect } from 'react'
import { Button, Logo } from '.'
import styled from '@emotion/styled'
import config from '../../config/site'

import FocusLock from 'react-focus-lock'
import { StaticImage } from 'gatsby-plugin-image'

//import useEventListener from '@use-it/event-listener'

const ESCAPE_KEYS = ['27', 'Escape']

const ContactModalScrim = styled.div`
	/* backdrop-filter: blur(8px); */
	position: fixed;
	z-index: 1999;
	transform: translateZ(2000px);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(var(--color-bg-rgb), 0.97);
	opacity: ${props => (props.visible ? 1 : 0)};
	transition: all 600ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
	pointer-events: ${props => (props.visible ? 'auto' : 'none')};
`

const ContactModalSheet = styled.div`
	position: fixed;
	z-index: 2000;
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
	border-radius: 0.5em 0.5em 0 0;

	/* transform-style: preserve-3d; */

	font-size: var(--fs-xl);
	bottom: 0;
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		align-content: center;
		bottom: 0;
		width: 100%;
	}

	padding: 1.5em 0.5em 1.5em 0.5em;
	background-color: var(--color-ink);
	color: var(--color-bg);

	box-shadow: 0px 10px 20px rgba(var(--color-bg-rgb), 0.22),
		0px 14px 56px rgba(var(--color-bg-rgb), 0.25);
	transform: ${props =>
		props.visible
			? 'translateY(0) translateZ(2000px)'
			: 'translateY(200%) translateZ(2000px)'};
	transition: all 900ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;

	&:focus {
		outline: none;
	}

	a {
		color: inherit;
		display: block;
		:hover,
		:focus {
			color: inherit;
		}
	}
`

const LogoCentered = styled.div`
	display: flex;
	position: absolute;
	justify-content: center;
	margin-bottom: 10vh;
	top: -36vh;
`

const CopyButton = styled(Button)`
	font-size: var(--fs-xs);
	padding: 0.9em 1.6em 0.95em 1.6em;
	color: var(--color-bg);
	border-color: var(--color-bg);
	&:focus-visible {
		border-color: ${props =>
			props.copied
				? 'rgba(var(--color-success-rgb), 0.07)'
				: 'rgba(var(--color-bg-rgb), 0.07)'};
		background-color: ${props =>
			props.copied
				? 'rgba(var(--color-success-rgb), 0.07)'
				: 'rgba(var(--color-bg-rgb), 0.07)'};
		box-shadow: inset 0 0 0 2px
			${props =>
				props.copied ? 'var(--color-success)' : 'var(--color-bg)'};
	}
`

const SecondaryContact = styled.div`
	border-top: 1px solid rgba(var(--color-bg-rgb), 0.2);
	margin-top: 8em;
	width: 80%;
	padding-top: 1em;
	display: flex;
	font-size: var(--fs-s);
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;

	a {
		margin: 0.5em;
	}
`

const ContactModal = ({
	render,
	visible,
	closeHandler,
	copied,
	copyHandler,
}) => {
	//useEventListener('keydown', handler)
	useEffect(() => {
		function handler(e) {
			if (ESCAPE_KEYS.includes(String(e.key))) {
				closeHandler()
				if (visible) {
					e.stopPropagation()
				}
			}
		}

		document.addEventListener('keydown', handler, true)
		return () => {
			document.removeEventListener('keydown', handler, true)
		}
	}, [closeHandler, visible])

	return (
		<>
			<ContactModalScrim visible={visible} onClick={closeHandler}>
				<>
					<StaticImage
						src="../assets/profile-contact.png"
						alt="Me"
						layout="fixed"
						aspectRatio={3 / 2}
						width={1280}
						style={{
							mixBlendMode: 'screen',
							top: '-16vh',
						}}
						imgStyle={{ maxWidth: '100vh' }}
					/>
					{visible && (
						<LogoCentered>
							<Logo
								isCentered
								//prefix="mobile"
								width="48vh"
								height="48vh"
								touchable={false}
							/>
						</LogoCentered>
					)}
				</>
			</ContactModalScrim>

			<ContactModalSheet
				visible={visible}
				role="dialog"
				aria-labelledby="Contact"
				aria-describedby="Contact me by mail, phone, twitter or follow me with RSS."
			>
				{render && (
					<FocusLock>
						<a href={'mailto:' + config.mail + config.mailSubject}>
							{config.mail}
						</a>
						<CopyButton onClick={copyHandler} copied={copied}>
							{copied ? 'Copied!' : 'Copy & Close'}
						</CopyButton>
						<SecondaryContact>
							{config.socialMedia.map(social => (
								<a
									className="no-underline focus-underline"
									key={social.name}
									aria-label={social.name}
									href={social.url}
									rel="noopener noreferrer"
									target="_blank"
								>
									{social.name}
								</a>
							))}
							<a
								className="no-underline focus-underline"
								href={'tel:' + config.phone}
							>
								Phone: {config.phoneFormatted}
							</a>
						</SecondaryContact>
					</FocusLock>
				)}
			</ContactModalSheet>
		</>
	)
}

export default ContactModal
