import styled from '@emotion/styled'

const UpdateBadge = styled.span`
	color: var(--color-accent);
	font-family: var(--font-default);
	display: table;
	line-height: 1;
	font-size: var(--fs-xxxs);
	border: 1px solid var(--color-accent);
	border-radius: 0.25em;
	padding: 0.5em 0.5em 0.6em 0.75em;
	margin: 1.25em 0 1.25em 0;
	background: none;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	background-color: rgba(var(--color-bg-rgb), 0.5);
`

export default UpdateBadge
