import React from 'react'

// import Cadiz from '../../../static/fonts/cadiz-web-book.woff2'
// import Boogy from '../../../static/fonts/boogy-brut-poster-web-white.woff2'

const Fonts = () => (
	<>
		<link
			rel="preload"
			as="font"
			href="/fonts/cadiz-variable-upright-web.woff2"
			type="font/woff2"
			crossOrigin="anonymous"
		/>
		<link
			rel="preload"
			as="font"
			href="/fonts/cadiz-variable-italic-web.woff2"
			type="font/woff2"
			crossOrigin="anonymous"
		/>
		<link
			rel="preload"
			as="font"
			href="/fonts/boogy-brut-poster-web-white.woff2"
			type="font/woff2"
			crossOrigin="anonymous"
		/>
	</>
)

export default Fonts
