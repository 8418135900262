import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
					pathPrefix
					title
					titleAlt
					description
					banner
					headline
					siteLanguage
					ogLanguage
					author
					twitter
					facebook
					themeColor
				}
			}
		}
	`)

	return data.site.siteMetadata
}

// siteUrl: config.siteUrl + pathPrefix, // For gatsby-plugin-sitemap
// pathPrefix,
// title: config.title,
// titleAlt: config.titleAlt,
// description: config.description,
// banner: config.banner,
// headline: config.headline,
// siteLanguage: config.siteLanguage,
// ogLanguage: config.ogLanguage,
// author: config.author,
// twitter: config.twitter,
// facebook: config.facebook,
// themeColor: config.themeColor,
