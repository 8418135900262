import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Logo, ContactButton } from '.'
import { Link } from 'gatsby'
import { IconAbout, IconBlog, IconProcess, IconProjects } from './Icons'
import useDocumentScrollThrottled from '../utils/useDocumentScrollThrottled'
import useWindowSize from '../utils/useWindowSize'

// prettier-ignore
const TabBarButton = styled(Link, {shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isLogo' && prop !== 'isContact' && prop !== 'as'})`
	background-image: none;
	min-width: 56px;
	margin: ${props => props.isLogo ? "-12px 12px 0 12px" : "0 4px"};
	flex-grow: 1;
	min-height: 48px;
	border-width: 0;
	color: var(--color-ink);
	/* background-color: rgba(0, 128, 255, 0.1); */
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: ${props => props.isContact ? '14px' : 'var(--fs-xxxs)'};
	
	opacity: ${props => (props.isActive || props.isContact || props.isLogo ? 1 : 0.6)};
	transition: opacity .2s linear, transform .3s ease-out, box-shadow .2s linear;
	cursor: ${props => props.isContact ? undefined : "pointer"};

	will-change: opacity, transform, box-shadow;

	& > * {
		transition: transform .8s ease;
		transform-origin: 50% 80%;
		@media (min-width: ${props => props.theme.breakpoints.m}) {
				transform-origin: 15% 15%;
			}
	}

	& svg.icon {
		width: 24px;
		height: 24px;
		stroke-width: ${props => props.isActive ? 1.5 : 1};
		transition: all .2s linear;
		margin-bottom: 2px;
		@media (min-width: ${props => props.theme.breakpoints.m}) {
			margin-bottom: 6px;
		}
	}

	display: ${props => (props.isContact ? 'none' : 'flex')};


	@media (min-width: ${props => props.theme.breakpoints.m}) {
		display: flex;
		font-size: ${props => props.isContact ? '15px' : 'var(--fs-xxxs)'};
		flex-grow: ${props => (props.isContact || props.isLogo ? 1 : 0)};
		order: ${props => (props.isLogo ? -1 : 0)};		
		justify-content: ${props => (props.isLogo ? 'flex-start' : props.isContact ? 'flex-end' : 'center')};
	}	 

	:hover, :focus {
		opacity: 1;
		
		outline: 2px solid transparent;

		& > * {
			transform: ${props => props.isLogo ? "scale(1.08)" : undefined};
			transition: transform .4s ease-out;	
				
		}
	}

	:focus-visible {
		outline: 2px solid transparent;
		box-shadow: 0 -2px 0 0 var(--color-bg), 0 -4px 0 0 var(--color-ink);
		@media (min-width: ${props => props.theme.breakpoints.m}) {
			box-shadow: -2px 0 0 0 var(--color-bg) inset,
			-4px 0 0 0 var(--color-ink) inset;
		}
	}

	:active {
		opacity: .7;
		& > * {
			transform: ${props => props.isLogo ? "scale(0.98)" : undefined};
			transition: transform .4s ease-out;		
		}
	}
`

const TabBarItem = ({ children, as, ...rest }) => {
	return (
		<TabBarButton as={as} {...rest}>
			{children}
		</TabBarButton>
	)
}

const TabBar = styled.nav`
	z-index: 750;
	display: ${props => (props.isFullscreen ? 'none' : 'flex')};
	align-items: flex-start;
	justify-content: space-evenly;
	background-color: rgb(var(--color-bg-rgb));

	width: 100%;
	height: 68px;

	padding: 4px 1em 24px 1em;

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		height: 100%;

		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		padding: 1em 6px 1em 4px;
		background-color: transparent;
	}
`

const TabBarText = styled(TabBar)`
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		& > a.tab > svg.icon {
			display: none;
			transform: rotate(180deg);
			width: 20px;
			height: 32px;
		}

		& > a.tab {
			writing-mode: vertical-lr;
			transform: rotate(180deg);
			flex-direction: row;

			/* text-transform: uppercase;
			letter-spacing: 0.25rem; */
			font-size: var(--fs-xs);
			height: auto;
			margin: 1em auto;
		}

		& > div > button:after {
			background: none;
		}
	}
`

const FixedBottomCssOnly = styled.div`
	position: fixed;
	z-index: 750;
	bottom: 0;
	padding-bottom: env(safe-area-inset-bottom);
	background-color: rgb(var(--color-bg-rgb));
	transform: ${props =>
		props.isHidden
			? 'translateY(108px) translateZ(100px)'
			: 'translateY(0) translateZ(100px)'};
	transition: background-color 0.2s linear, transform 0.15s ease-in-out;
	/* transform-style: preserve-3d; */
	width: 100%;

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		background-color: transparent;
		width: 4rem;
		transform: translateY(0) translateZ(0);
		padding-bottom: 0;
		height: 100%;
		/* box-shadow: 0.5px 0 0 0 rgba(var(--color-ink-rgb), 0.2); */
	}
`

const NavigationBar = ({ triggerContactModal, contactModal, current }) => {
	const [shouldHideHeader, setShouldHideHeader] = useState(false)
	const windowSize = useWindowSize()

	const MINIMUM_SCROLL = 50
	const MINIMUM_SCROLL_DISTANCE = 90
	const TIMEOUT_DELAY = 0
	const REAPPEAR_AT_BOTTOM = 150

	const isSSR = typeof window === 'undefined'

	useDocumentScrollThrottled(callbackData => {
		const { previousScrollTop, currentScrollTop, currentScrollHeight } =
			callbackData
		const isScrolledDown = previousScrollTop < currentScrollTop
		const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL
		const isMinimumScrolledDistance =
			Math.abs(previousScrollTop - currentScrollTop) >
			MINIMUM_SCROLL_DISTANCE
		const isCloseToBottom =
			currentScrollHeight -
				currentScrollTop -
				windowSize.height -
				REAPPEAR_AT_BOTTOM <
			0

		setTimeout(() => {
			// console.log(
			// 	'scroll',
			// 	currentScrollHeight -
			// 		currentScrollTop -
			// 		windowSize.height -
			// 		REAPPEAR_AT_BOTTOM
			// )
			// console.log(
			// 	'scrollHeight',
			// 	currentScrollHeight,
			// 	'-',
			// 	windowSize.height,
			// 	'=',
			// 	currentScrollHeight - windowSize.height - REAPPEAR_AT_BOTTOM
			// )
			// console.log(shouldHideHeader)

			if (isMinimumScrolledDistance || isCloseToBottom) {
				setShouldHideHeader(
					isScrolledDown && isMinimumScrolled && !isCloseToBottom
				)
			}
		}, TIMEOUT_DELAY)
	})

	return (
		<FixedBottomCssOnly isHidden={shouldHideHeader}>
			<TabBarText
				isFullscreen={
					isSSR ? false : document.webkitIsFullScreen === true
				}
			>
				<TabBarItem
					className="tab"
					to="/"
					isActive={current === '' || current === '*'}
				>
					<IconAbout /> About
				</TabBarItem>
				<TabBarItem
					className="tab"
					to="/projects"
					isActive={current === 'projects'}
				>
					<IconProjects /> Projects
				</TabBarItem>
				<TabBarItem
					isLogo
					as="div"
					onClick={triggerContactModal}
					title="Contact"
				>
					<Logo width="56px" height="56px" touchable={false} />
				</TabBarItem>

				<TabBarItem
					className="tab"
					to="/process"
					isActive={current === 'process'}
				>
					<IconProcess /> Process
				</TabBarItem>

				<TabBarItem
					className="tab"
					to="/blog"
					isActive={current === 'blog'}
				>
					<IconBlog /> Blog
				</TabBarItem>
				<TabBarItem isContact as="div">
					<ContactButton onClick={triggerContactModal} />
				</TabBarItem>
			</TabBarText>
		</FixedBottomCssOnly>
	)
}

NavigationBar.propTypes = {
	triggerContactModal: PropTypes.func,
	contactModal: PropTypes.bool,
	current: PropTypes.string,
}

NavigationBar.defaultProps = {
	current: '',
}

export default NavigationBar
