import React from 'react'

function Replay(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				stroke="currentColor"
			>
				<path d="M9.5 15.5l6-3-6-3z" />
				<path d="M3 12v4a5 5 0 005 5h8a5 5 0 005-5V9a5 5 0 00-5-5H4l4-3" />
			</g>
		</svg>
	)
}

const MemoReplay = React.memo(Replay)
export default MemoReplay
