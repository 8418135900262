import styled from '@emotion/styled'

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(
		${props =>
			props.isSmall
				? props.theme.gridColumns.small.xxs
				: props.theme.gridColumns.large.xxs},
		1fr
	);
	grid-gap: ${props => (props.isSmall ? '0.5em' : '0.75em')};

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		grid-template-columns: repeat(
			${props =>
				props.isSmall
					? props.theme.gridColumns.small.xs
					: props.theme.gridColumns.large.xs},
			1fr
		);
	}

	@media (min-width: ${props => props.theme.breakpoints.s}) {
		grid-template-columns: repeat(
			${props =>
				props.isSmall
					? props.theme.gridColumns.small.s
					: props.theme.gridColumns.large.s},
			1fr
		);
	}

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		grid-template-columns: repeat(
			${props =>
				props.isSmall
					? props.theme.gridColumns.small.m
					: props.theme.gridColumns.large.m},
			1fr
		);
	}

	@media (min-width: ${props => props.theme.breakpoints.ml}) {
		grid-gap: ${props => (props.isSmall ? '0.66em' : '0.85em')};
		grid-template-columns: repeat(
			${props =>
				props.isSmall
					? props.theme.gridColumns.small.ml
					: props.theme.gridColumns.large.ml},
			1fr
		);
	}

	@media (min-width: ${props => props.theme.breakpoints.l}) {
		grid-template-columns: repeat(
			${props =>
				props.isSmall
					? props.theme.gridColumns.small.l
					: props.theme.gridColumns.large.l},
			1fr
		);
	}

	.gatsby-image-outer-wrapper,
	.gatsby-image-wrapper {
		position: static !important;
	}
`

export default Grid
