import React from 'react'
import styled from '@emotion/styled'

const Logo = styled.img`
	@keyframes rotating {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	height: 100% !important;
	width: auto !important;

	transform-origin: center;
	animation: rotating 14s linear infinite;
`

const LogoPicture = styled.picture`
	width: auto;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`

export default function LogoImage(props) {
	return (
		<LogoPicture>
			<source srcSet="/assets/logo.webp" type="image/webp" />
			<Logo
				src="/assets/logo.png"
				alt="Striped Torus Ring"
				width={56}
				height={56}
				className="logo"
			/>
		</LogoPicture>
	)
}
