/* eslint react/prop-types: 0 */
import React from 'react'
import styled from '@emotion/styled'
import { IconContact } from './Icons'

const CtA = styled.button`
	pointer-events: visible;
	background-image: none;
	height: calc(68px + 6px);
	width: 85%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--fs-m);
	top: -6px;

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		width: 4em;
		top: 12px;
	}

	color: var(--color-ink);
	position: relative;
	top: -6px;
	background: none;

	border-width: 0;
	margin: 0 1em;
	transition: transform 0.3s ease-out, box-shadow 0.3s linear;

	& > svg.icon {
		width: 28px;
		height: 28px;
		margin-bottom: 0;
		margin-top: -24px;
		@media (min-width: ${props => props.theme.breakpoints.m}) {
			margin-top: 0;
		}
	}

	&:hover {
		outline: 2px solid transparent;
		color: var(--color-ink);
		transform: translateY(-2px);
	}

	:focus-visible {
		outline: 2px solid transparent;
		color: var(--color-ink);
		transform: translateY(-2px);
		box-shadow: 6px 0 0 0 var(--color-bg) inset,
			8px 0 0 0 var(--color-ink) inset;
	}

	&:active {
		transform: scale(0.95);
	}
`

const ContactButton = ({ onClick }) => {
	return (
		<>
			<CtA onClick={onClick} aria-label="Contact">
				<IconContact />
			</CtA>
		</>
	)
}

export default ContactButton
