import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import Fonts from './Fonts'
import Icons from './Icons'
import { getSrc } from 'gatsby-plugin-image'
import { useSiteMetadata } from '../../utils/useSiteMetadata'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const Seo = ({
	title,
	desc,
	// banner,
	pathname,
	article,
	node,
	background,
	noIndex,
}) => {
	// const { site } = useStaticQuery(query)

	// const {
	// 	buildTime,
	// 	siteMetadata: {
	// 		siteUrl,
	// 		defaultTitle,
	// 		defaultDescription,
	// 		defaultBanner,
	// 		headline,
	// 		siteLanguage,
	// 		ogLanguage,
	// 		author,
	// 		twitter,
	// 		facebook,
	// 		themeColor,
	// 	},
	// } = site

	const {
		siteUrl,
		titleAlt,
		description,
		banner,
		headline,
		siteLanguage,
		ogLanguage,
		author,
		twitter,
		facebook,
		themeColor,
	} = useSiteMetadata()
	const defaultBanner = banner
	const defaultDescription = description
	const defaultTitle = titleAlt

	const seo = {
		title: title ? `${title} – ${defaultTitle}` : defaultTitle,
		description: desc || defaultDescription,
		image: `${siteUrl}${banner || defaultBanner}`,
		url: `${siteUrl}${pathname || ''}`,
	}

	if (article) {
		seo.title = `${node.frontmatter.title} — ${defaultTitle}`
		seo.description = node.excerpt || desc || defaultDescription
		seo.image = getSrc(node.frontmatter.cover) //`${siteUrl}${node.frontmatter.cover.childImageSharp.resize.src}`
	}

	// schema.org in JSONLD format
	// https://developers.google.com/search/docs/guides/intro-structured-data
	// You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

	const schemaOrgWebPage = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		url: siteUrl,
		headline,
		inLanguage: siteLanguage,
		mainEntityOfPage: siteUrl,
		description: defaultDescription,
		name: defaultTitle,
		author: {
			'@type': 'Person',
			name: author,
		},
		copyrightHolder: {
			'@type': 'Person',
			name: author,
		},
		copyrightYear: '2020',
		creator: {
			'@type': 'Person',
			name: author,
		},
		publisher: {
			'@type': 'Person',
			name: author,
		},
		datePublished: '2020-01-18T10:30:00+01:00',
		// dateModified: buildTime,
		image: {
			'@type': 'ImageObject',
			url: `${siteUrl}${defaultBanner}`,
		},
	}

	// Initial breadcrumb list

	const itemListElement = [
		{
			'@type': 'ListItem',
			item: {
				'@id': siteUrl,
				name: 'Homepage',
			},
			position: 1,
		},
	]

	let schemaArticle = null

	if (article) {
		schemaArticle = {
			'@context': 'http://schema.org',
			'@type': 'Article',
			author: {
				'@type': 'Person',
				name: author,
			},
			copyrightHolder: {
				'@type': 'Person',
				name: author,
			},
			copyrightYear: '2019',
			creator: {
				'@type': 'Person',
				name: author,
			},
			publisher: {
				'@type': 'Organization',
				name: author,
				logo: {
					'@type': 'ImageObject',
					url: `${siteUrl}${defaultBanner}`,
				},
			},
			datePublished: node.parent.birthtime,
			dateModified: node.parent.mtime,
			description: seo.description,
			headline: seo.title,
			inLanguage: siteLanguage,
			url: seo.url,
			name: seo.title,
			image: {
				'@type': 'ImageObject',
				url: seo.image,
			},
			mainEntityOfPage: seo.url,
		}
		// Push current blogpost into breadcrumb list
		itemListElement.push({
			'@type': 'ListItem',
			item: {
				'@id': seo.url,
				name: seo.title,
			},
			position: 2,
		})
	}

	const breadcrumb = {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		description: 'Breadcrumbs list',
		name: 'Breadcrumbs',
		itemListElement,
	}

	return (
		<>
			<title>{seo.title}</title>
			{noIndex && <meta name="robots" content="noindex" />}
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			{/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
			{!article && (
				<script type="application/ld+json">
					{JSON.stringify(schemaOrgWebPage)}
				</script>
			)}
			{article && (
				<script type="application/ld+json">
					{JSON.stringify(schemaArticle)}
				</script>
			)}
			<script type="application/ld+json">
				{JSON.stringify(breadcrumb)}
			</script>

			<link rel="me" href="https://mastodon.social/@richy" />

			<Facebook
				desc={seo.description}
				image={seo.image}
				title={seo.title}
				type={article ? 'article' : 'website'}
				url={seo.url}
				locale={ogLanguage}
				name={facebook}
			/>
			<Twitter
				title={seo.title}
				image={seo.image}
				desc={seo.description}
				username={twitter}
			/>
			<Icons themeColor={background || themeColor} />
			<Fonts />
		</>
	)
}

export default Seo

Seo.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	banner: PropTypes.string,
	pathname: PropTypes.string,
	article: PropTypes.bool,
	node: PropTypes.object,
	background: PropTypes.string,
	noIndex: PropTypes.bool,
	// isDark: PropTypes.bool,
}

Seo.defaultProps = {
	title: null,
	desc: null,
	banner: null,
	pathname: null,
	article: false,
	node: null,
	background: '',
	noIndex: false,
	// isDark: true,
}

// const query = graphql`
// 	query SEO {
// 		site {
// 			buildTime(formatString: "YYYY-MM-DD")
// 			siteMetadata {
// 				siteUrl
// 				defaultTitle: titleAlt
// 				defaultDescription: description
// 				defaultBanner: banner
// 				headline
// 				siteLanguage
// 				ogLanguage
// 				author
// 				twitter
// 				facebook
// 				themeColor
// 			}
// 		}
// 	}
// `
