import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'gatsby'
import config from '../../config/site'
import { myContext } from '../utils/provider'

const Content = styled.footer`
	color: var(--color-ink);
	text-align: center;
	font-size: var(--fs-xs);
	line-height: 1.3;
	padding: 1rem 1rem;
	height: 12em;
	flex: none;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		margin-left: 5rem;
		height: 7em;
	}
`

const footerItems = css`
	transition: color 0.3s linear, border-bottom 0.3s linear,
		transform 0.3s ease-out;
	display: flex;
	align-items: center;
	font-size: var(--fs-xs);
	border-width: 0;
	border-bottom: 2px solid rgba(var(--color-ink-rgb), 0);
	line-height: 1;
	padding: 0;
	margin: 0 0.67em;
	color: rgba(var(--color-ink-rgb), 0.6);
	background: transparent;
	height: 48px;
	&:hover {
		color: rgba(var(--color-ink-rgb), 0.9);
	}
	&:active {
		color: rgba(var(--color-ink-rgb), 0.6);
		transform: translateY(2px);
	}
	&:focus-visible {
		outline: 2px solid transparent;
		border-bottom: 2px solid rgba(var(--color-ink-rgb), 1);
		color: rgba(var(--color-ink-rgb), 1);
	}
`

const FooterLink = styled(Link)`
	${footerItems}
`

const FooterButton = styled.button`
	${footerItems}
`

const FooterDivider = styled.span`
	color: rgba(var(--color-ink-rgb), 0.6);
	flex-grow: 1;
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		max-width: 1.33em;
	}
`

const Footer = ({ location }) => {
	return (
		<myContext.Consumer>
			{context => (
				<Content>
					<FooterButton onClick={context.openContactModal}>
						Contact
					</FooterButton>
					{config.socialMedia.map(
						social =>
							social.inFooter && (
								<FooterButton
									as="a"
									key={social.name}
									href={social.url}
									target="_blank"
									rel={social.ref}
								>
									{social.name}
								</FooterButton>
							)
					)}
					<FooterDivider />
					<FooterLink
						to="/legal#impressum"
						state={{ origin: location.path }}
					>
						Impressum
					</FooterLink>
					<FooterLink
						to="/legal#datenschutz"
						state={{ origin: location.path }}
					>
						Privacy
					</FooterLink>
				</Content>
			)}
		</myContext.Consumer>
	)
}

export default Footer
