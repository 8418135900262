import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ThemeProvider } from '@emotion/react'
import GlobalStyle from '../styles/GlobalStyle'
import { Footer } from '.'
import { theme } from '../../config/theme'
import { getLuminance } from 'polished'
import { myContext } from '../utils/provider'

const AbsoluteWrapper = styled.div`
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	/* background-color: var(--color-bg, #141414);
	transition: background-color 0.3s linear; */
	margin-top: 3em;
`

const PageContent = styled.main`
	flex: 1;
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		margin-left: 4rem;
		padding: 0 0.5em;
	}
	@media (min-width: ${props => props.theme.breakpoints.ml}) {
		margin-left: 4rem;
		padding: 0 1em;
	}
`

const Layout = ({
	children,
	background,
	backgroundGradient,
	location,
	...props
}) => {
	const context = useContext(myContext)
	const [isDark, setIsDark] = useState(context.isDark)

	useEffect(() => {
		if (background) setIsDark(getLuminance(background) <= 0.18)
		else setIsDark(context.isDark)
	}, [background, context.isDark])

	return (
		<ThemeProvider
			theme={theme({
				mode: isDark ? 'dark' : 'light',
			})}
		>
			<GlobalStyle
				backgroundGradient={backgroundGradient}
				background={background}
				theme={theme({
					mode: isDark ? 'dark' : 'light',
				})}
			/>
			<noscript>To browse this site, please enable JavaScript.</noscript>
			<AbsoluteWrapper>
				<PageContent>{children}</PageContent>
				<Footer location={location} />
			</AbsoluteWrapper>
		</ThemeProvider>
	)
}

export default Layout

Layout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
	customSEO: PropTypes.bool,
	backgroundGradient: PropTypes.bool,
	noIndex: PropTypes.bool,
}

Layout.defaultProps = {
	customSEO: false,
	backgroundGradient: false,
	noIndex: false,
}
