import React, { useState } from 'react'
import * as config from '../../config/site'

export const myContext = React.createContext()

const Provider = props => {
	const [isDark, setTheme] = useState(config.mode === 'dark')
	const [contactModal, setContactModal] = useState(false)
	const [renderContactModal, setRenderContactModal] = useState(false)
	const [mailCopied, setMailCopied] = useState(false)

	const openContactModal = () => {
		setContactModal(true)
		setTimeout(() => setRenderContactModal(true), 200)
	}
	const closeContactModal = () => {
		setContactModal(false)
		setTimeout(() => setRenderContactModal(false), 500)
	}

	const mailCopyHandler = () => {
		navigator.clipboard.writeText(config.mail)
		setMailCopied(true)
		setTimeout(closeContactModal(), 500)
		setTimeout(() => setMailCopied(false), 1200)
	}

	return (
		<myContext.Provider
			value={{
				isDark,
				changeTheme: () => setTheme(!isDark),
				contactModal,
				renderContactModal,
				openContactModal,
				closeContactModal,
				mailCopied,
				mailCopyHandler,
			}}
		>
			{props.children}
		</myContext.Provider>
	)
}

export default Provider
