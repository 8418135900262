import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { ThemeProvider } from '@emotion/react'
import { theme } from '../../config/theme'
import Provider, { myContext } from './provider'
import shortcodes from './shortcodes'

export const wrapRootElement = ({ element }) => {
	return (
		<Provider>
			<myContext.Consumer>
				{context => (
					<ThemeProvider
						theme={theme({
							mode: context.isDark ? 'dark' : 'light',
						})}
					>
						<MDXProvider components={shortcodes}>
							{element}
						</MDXProvider>
					</ThemeProvider>
				)}
			</myContext.Consumer>
		</Provider>
	)
}

export default wrapRootElement
