import PropTypes from 'prop-types'
import React from 'react'

const Icons = ({ themeColor }) => (
	<>
		<link
			rel="apple-touch-icon"
			sizes="180x180"
			href="/icons/icon-maskable-180x180.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="152x152"
			href="/icons/icon-maskable-152x152.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="167x167"
			href="/icons/icon-maskable-167x167.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="96x96"
			href="/icons/icon-maskable-96x96.png"
		/>
		<link
			rel="icon"
			type="image/png"
			sizes="96x96"
			href="/icons/icon-96x96.png"
		/>
		<link
			rel="icon"
			type="image/png"
			sizes="32x32"
			href="/icons/icon-32x32.png"
		/>
		<link
			rel="icon"
			type="image/png"
			sizes="16x16"
			href="/icons/icon-16x16.png"
		/>
		<meta name="msapplication-TileColor" content={themeColor} />
		<meta
			name="apple-mobile-web-app-status-bar-style"
			content="black-translucent"
		/>
		<meta name="theme-color" content={themeColor} />
	</>
)

export default Icons

Icons.propTypes = {
	themeColor: PropTypes.string,
}

Icons.defaultProps = {
	themeColor: '#141414',
}
