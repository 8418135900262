import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const Wrapper = styled.div`
	display: flex;
	position: relative;
`

const Content = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: ${props => props.theme.maxWidths.general};
	padding: 2rem 1.0875rem 4rem 1.0875rem;
	color: var(--color-ink);
`

const Details = styled.div`
	max-width: ${props => props.theme.maxWidths.text};
	margin: 2em auto 0 auto;

	h2 {
		color: var(--color-ink);
		font-size: var(--fs-hero);
		margin: 0 auto 0 auto;
		font-family: var(--font-display);
		font-weight: var(--weight-display);

		@media (min-width: ${props => props.theme.breakpoints.s}) {
			margin-bottom: 1rem;
		}
		@media (min-width: ${props => props.theme.breakpoints.m}) {
			margin-bottom: 1.5rem;
		}
		@media (min-width: ${props => props.theme.breakpoints.l}) {
			margin-bottom: 2rem;
		}
	}

	div {
		margin-top: 0;
	}
`

const Text = styled.div`
	color: var(--color-ink);
	margin: 0 auto 2em auto;
	text-align: left;
	font-size: var(--fs-l);

	@media (min-width: ${props => props.theme.breakpoints.s}) {
		margin: 4em auto 2em auto;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	blockquote,
	pre,
	hr,
	p {
		max-width: ${props => props.theme.maxWidths.text};
		margin: 1em auto 1em auto;
		font-weight: var(--weight-default);
		line-height: 1.5;
	}

	h1,
	h2,
	h3 {
		font-family: var(--font-default);
		font-size: var(--fs-xxl);
		font-weight: var(--weight-title);
		letter-spacing: var(--ls-title);
		line-height: 1.25;
		margin-top: 3em;
	}

	h4 {
		font-size: var(--fs-xl);
	}

	ul {
		padding: 0;
	}

	li {
		margin-bottom: 0.5em;
	}

	.gatsby-resp-image-figure,
	.gatsby-resp-image-wrapper,
	video,
	iframe {
		margin: 4em auto 4em auto;
	}

	.gatsby-resp-image-figure .gatsby-resp-image-wrapper,
	figure video {
		margin: 0;
	}
`

const ContentLayout = ({ title, date, areas, text, archive, collection }) => {
	const contentAnimation = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		transition: {
			type: 'spring',
			mass: 1,
			stiffness: 40,
			damping: 10,
			delay: 0.2,
		},
	}

	//const themeContext = useContext(ThemeContext)

	return (
		<Wrapper>
			<Content>
				<Details>
					<motion.h2>{title}</motion.h2>
					{/*<div>
								{areas.map(
									(area, index) => (
										<React.Fragment
											key={area}
										>
											{index > 0 &&
												", "}
											{area}
										</React.Fragment>
									)
								)}
							</div>*/}
				</Details>
				<motion.div {...contentAnimation}>
					{text && <Text>{text}</Text>}
				</motion.div>
			</Content>
		</Wrapper>
	)
}

export default ContentLayout

ContentLayout.propTypes = {
	// logo: PropTypes.string.isRequired,
	// name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	date: PropTypes.string,
	areas: PropTypes.array,
	text: PropTypes.string,
	arrow: PropTypes.bool,
	collection: PropTypes.string,
}

ContentLayout.defaultProps = {
	date: '',
	areas: [],
	text: '',
	arrow: true,
	collection: '',
}
