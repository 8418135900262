import React from 'react'

function Close(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" stroke="currentColor" fillRule="evenodd">
				<path strokeLinecap="round" d="M5 19L19 5M19 19L5 5" />
			</g>
		</svg>
	)
}

const MemoClose = React.memo(Close)
export default MemoClose
