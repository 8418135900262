const baseTheme = {
	maxWidths: {
		general: '1600px',
		project: 1600,
		grids: '1680px',
		slim: '56em',
		text: '40rem',
		none: 'none',
	},
	fonts: {
		default:
			'Cadiz, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
		display:
			'Boogy, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
	},
	weights: {
		default: 200,
		display: 300,
		eyebrow: 140,
		subtleTitle: 320,
		title: 400,
		action: 260,
	},
	opacities: {
		eyebrow: 0.7,
	},
	letterSpacings: {
		eyebrow: 0.025,
		title: -0.01,
	},
	lineHeights: {
		headlines: 0.9, // 1.05,
		display: 0.8,
	},
	gridColumns: {
		large: { xxs: 1, xs: 1, s: 2, m: 2, ml: 3, l: 3, xl: 3 },
		small: { xxs: 1, xs: 2, s: 2, m: 3, ml: 3, l: 4, xl: 4 },
	}, // Number of columns of the grid on the index page
	contentPadding: '1.0875rem',
	gridPadding: '1.0875rem',
	breakpoints: {
		xs: '400px',
		s: '600px',
		m: '900px',
		ml: '1194px',
		l: '1366px',
		xl: '1600px',
	},
}

export const darkTheme = {
	...baseTheme,
	colors: {
		bg: '#141414',
		ink: '#ffffff',
		link: 'rgba(255,255,255,1)',
		linkHover: 'rgba(255,255,255,.7)',
		selection: 'rgba(120,0,220,.6)',
		accent: '#00FFAA',
		success: '#00FFAA',
	},
}

export const lightTheme = {
	...baseTheme,
	colors: {
		bg: '#fff',
		ink: '#141414',
		link: 'rgba(25,25,25,1)',
		linkHover: 'rgba(25,25,25,.7)',
		selection: 'rgba(120,0,220,.6)',
		accent: '#00DD44',
		success: '#00DD44',
	},
}

export const theme = ({ mode }) => (mode === 'light' ? lightTheme : darkTheme)
