import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { rgba } from 'polished'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { UpdateBadge } from '.'
import isValidProp from '@emotion/is-prop-valid'

const Cover = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	div {
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	picture {
		transition: opacity 0.5s ease-out;
		z-index: 0;
		opacity: 0.8;
	}
	background: #141414;
`
// prettier-ignore
const CardItem = styled(({ isSmall, textColor, shadow, ...props }) => (<Link className="no-underline" {...props} />))`
	
	position: relative;
	overflow: hidden;
	border-radius: 0.25rem;
	box-shadow: 0 1px 2px 0 ${(props) => rgba(props.shadow, 0.25)},
		0 2px 4px -1px ${(props) => rgba(props.shadow, 0.25)},
		0 4px 8px -2px ${(props) => rgba(props.shadow, 0.25)};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: ${(props) => props.textColor};
	transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transform: perspective(500px) translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);

	&:hover {
		color: ${(props) => props.textColor};
		transform: perspective(500px) translate3d(0, -6px, 10px) rotate3d(8, -2, -3, 1deg);
		box-shadow: 0 1px 2px 0 ${(props) => rgba(props.shadow, 0.25)},
			0 20px 40px ${(props) => rgba(props.shadow, 0.3)},
			0 6px 12px ${(props) => rgba(props.shadow, 0.5)};
			
		${Cover} picture {
			opacity: 1;
		}
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		color: ${(props) => props.textColor};
		transform: perspective(500px) translate3d(0, -6px, 10px) rotate3d(8, -2, -3, 1deg);
		outline: none;
		${Cover} picture {
			opacity: 1;
		}
		box-shadow: 0 0 0 2px var(--color-ink), 0 1px 2px 0 ${(props) => rgba(props.shadow, 0.25)},
			0 20px 40px ${(props) => rgba(props.shadow, 0.3)},
			0 6px 12px ${(props) => rgba(props.shadow, 0.5)};
	}

	&:active {
		color: ${(props) => props.textColor};
		transform: perspective(500px) translate3d(0, 0, 0);
		box-shadow: 0 1px 2px 0 ${(props) => rgba(props.shadow, 0.25)},
			0 2px 8px ${(props) => rgba(props.shadow, 0.4)},
			0 1px 3px ${(props) => rgba(props.shadow, 0.5)};
	}

	min-height: ${(props) => (props.isSmall ? '140px' : '360px')};

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		min-height: ${props => (props.isSmall ? '220px' : '400px')};
	}

	@media (min-width: ${props => props.theme.breakpoints.s}) {
		min-height: ${props => (props.isSmall ? '340px' : '440px')};
	}

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		min-height: ${props => (props.isSmall ? '320px' : '480px')};
	}

	@media (min-width: ${props => props.theme.breakpoints.ml}) {
		min-height: ${props => (props.isSmall ? '340px' : '440px')};
	}

	@media (min-width: ${props => props.theme.breakpoints.l}) {
		min-height: ${props => (props.isSmall ? '380px' : '520px')};
	}

	@media (min-width: ${props => props.theme.breakpoints.xl}) {
		min-height: ${props => (props.isSmall ? '400px' : '580px')};
	}

	h3 {
		max-width: 9.5em;
		margin-bottom: 0;
		margin: 0;
		padding: 1em;
		
		font-size: ${props => (props.isSmall ? '1em' : '1.25em')};

		font-family: var(--font-default);
		font-weight: var(--weight-subtle-title);
		line-height: 1.1;
	}
`

const Content = styled.div`
	position: relative;
	transition: all 0.3s ease-out;
	opacity: 1;
	background: ${props =>
		`linear-gradient(173deg, ${props.background} 0%, ${rgba(
			props.background,
			0
		)} 70%)`};

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: 4em;

	${CardItem}:hover &,
	${CardItem}:focus & {
		opacity: 1;
	}
`

const GridItem = styled(motion.div, { shouldForwardProp: isValidProp })`
	@media (min-width: ${props => props.theme.breakpoints.s}) {
		grid-column: ${props => (props.isWider ? 'auto / span 2' : undefined)};
	}
	@media (min-width: ${props => props.theme.breakpoints.ml}) {
		grid-column: ${props =>
			props.isRecent && props.isWider ? 'auto / span 1' : undefined};
	}
`

const Card = ({
	path,
	cover,
	title,
	delay,
	color,
	background,
	shadow,
	isSmall,
	updated,
	isWider,
	isRecent,
}) => {
	const animation = {
		initial: { opacity: 0, y: 30 },
		animate: { opacity: 1, y: 0 },
		transition: {
			opacity: { ease: 'linear', delay: 0.03 * delay },

			type: 'spring',
			mass: 1,
			stiffness: 60,
			damping: 20 + 1 * delay,
			delay: 0.03 * delay,
		},
	}

	return (
		<GridItem
			{...animation}
			isWider={isWider || false}
			isRecent={isRecent || false}
		>
			<CardItem
				to={path}
				textColor={color}
				shadow={shadow}
				isSmall={isSmall}
			>
				<Cover>
					<GatsbyImage
						image={cover}
						style={{ willChange: 'transform' }}
						alt=""
					/>
				</Cover>
				<Content textColor={color} background={background}>
					<h3>
						{title}
						{updated && <UpdateBadge>Updated</UpdateBadge>}
					</h3>
				</Content>
			</CardItem>
		</GridItem>
	)
}

export default Card

Card.propTypes = {
	path: PropTypes.string.isRequired,
	cover: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	delay: PropTypes.number.isRequired,
	color: PropTypes.string,
	background: PropTypes.string,
	shadow: PropTypes.string,
	isSmall: PropTypes.bool,
	updated: PropTypes.bool,
	recent: PropTypes.bool,
}

Card.defaultProps = {
	color: 'black',
	background: 'white',
	shadow: '#000000',
	isSmall: false,
	updated: false,
	recent: false,
}
