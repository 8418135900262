import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

// prettier-ignore
const StyledImageListItem = styled(({ url, podcast, children, ...props }) => (<a href={url} {...props}>{children}</a>))`
	position: relative;
	overflow: visible;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	background: none;
	max-width: ${props => props.theme.maxWidths.text};
	margin: 1em auto;
	padding: 1em 0;
	transition: background-color ease-out 600ms;

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		padding: 1em 1em;
	}

	& > div {
		min-width: 25%;
		@media (min-width: ${props => props.theme.breakpoints.m}) {
			min-width: 18%;
		}
	}

	& .gatsby-resp-image-wrapper {	
		opacity: 0.85;
		height: auto;
		border-radius: ${(props) =>
			props.podcast ? '0.125rem' : '0.25rem 0 0 0.25rem'};
		overflow: hidden;
		margin: 0 !important;
		margin-top: 0.45em !important;
		border-bottom: ${(props) =>
			props.podcast
				? '0px solid rgba(var(--color-ink-rgb), 0.9)'
				: '3px solid rgba(var(--color-ink-rgb), 0.9)'};
		transition: transform ease-out 0.6s, opacity ease-out 0.6s, box-shadow ease-out 0.6s;
		box-shadow: 0 4px 2px -1px rgba(0, 0, 0, 1);
	}

	border: 2px solid rgba(var(--color-ink-rgb), 0);
	border-radius: 0.25em;
	background-color: rgba(var(--color-ink-rgb), 0);

	&:hover {
		background-color: rgba(255,255,255,0.04);
		transition: background-color ease-out 300ms;
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline: 2px solid transparent;
		border: 2px solid rgba(var(--color-ink-rgb), 0.5);
		background-color: rgba(var(--color-ink-rgb), 0.05);
		box-shadow: none;
	}

	&:hover .gatsby-resp-image-wrapper,
	&:focus .gatsby-resp-image-wrapper {
		opacity: 1;
		transform: translateY(-4px) scale(1.03);
		box-shadow: 0 8px 8px -1px rgba(0, 0, 0, 1);
		transition: transform ease-out 0.3s, opacity ease-out 0.3s, box-shadow ease-out 0.3s;
	}

	&:active .gatsby-resp-image-wrapper {
		opacity: 1;
		transform: translateY(0px) scale(1);
		box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 1),
			inset 2px 0 2px 0 rgba(0, 0, 0, 0.5);
	}
`

const Content = styled.div`
	width: 75%;
	padding: 0.5em 0 0.5em 1em;
	@media (min-width: ${props => props.theme.breakpoints.s}) {
		width: 75%;
		padding-left: 2em;
	}
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		width: 70%;
		padding-left: 2em;
	}
	position: relative;
	transition: all 0.3s ease-out;

	h4 {
		padding: 0 !important;
		margin: 0 0 0.25em 0 !important;
		line-height: 1.125 !important;
		font-size: var(--fs-l);
		max-width: 12ch;
		font-weight: var(--weight-title);
		letter-spacing: var(--ls-title);
		@media (min-width: ${props => props.theme.breakpoints.s}) {
			font-size: var(--fs-xl);
		}
	}
`

const Subtitle = styled.p`
	font-size: var(--fs-xs);
	opacity: 0.8;
	line-height: 1.33 !important;
	margin: 0.125em 0 !important;
	max-width: 32ch !important;
`

const Author = styled.p`
	font-size: var(--fs-xs);
	opacity: 0.8;
	line-height: 1.33 !important;
	margin: 0.125em 0 0.5em 0 !important;
`

const Notes = styled.p`
	font-style: italic;
	opacity: 0.6;
	font-size: var(--fs-xs);
	max-width: 40ch !important;
	line-height: 1.33 !important;
	margin: 1em 0 !important;
`

const Button = styled.div`
	color: var(--color-ink);
	text-align: center;
	font-family: var(--font-default);
	font-weight: var(--weight-action);
	display: inline-block;
	line-height: 1;
	font-size: var(--fs-xs);
	margin-top: 2em;
	border: 1px solid rgba(var(--color-ink-rgb), 0.8);
	border-radius: 2em;
	padding: 0.5em 1em 0.6em 1em;
	opacity: 0.5;
	background: none;
	background-color: rgba(var(--color-ink-rgb), 0);
	transition: all 0.3s ease-out;
	&:hover,
	&:focus {
		opacity: 0.8;
		background-color: rgba(var(--color-ink-rgb), 0.07);
	}
	&:focus {
		outline: 2px solid transparent;
		box-shadow: inset 0 0 0 2px rgba(var(--color-ink-rgb), 1);
	}

	${StyledImageListItem}:hover &,
	${StyledImageListItem}:focus & {
		opacity: 0.8;
		background-color: rgba(var(--color-ink-rgb), 0.07);
	}
`

const ContentImageListItem = ({
	title,
	subtitle,
	author,
	notes,
	url,
	bookClubUrl,
	podcast,
	children,
	year,
}) => {
	//const themeContext = useContext(ThemeContext)

	return (
		<StyledImageListItem url={url} podcast={podcast}>
			{children}
			<Content>
				<h4>{title}</h4>
				{subtitle && <Subtitle>{subtitle}</Subtitle>}
				<Author>
					{podcast ? 'Hosted by ' + author : 'by ' + author}
					{year && ' · ' + year + ''}
				</Author>
				{notes && <Notes>{notes}</Notes>}
				<Button>{podcast ? 'Listen' : 'Get the Book'}</Button>
				{/* {bookClubUrl && (
					<>
						<br />
						<Button>View on Oku</Button>
					</>
				)} */}
			</Content>
		</StyledImageListItem>
	)
}

export default ContentImageListItem

ContentImageListItem.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	author: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,

	notes: PropTypes.string,
	bookClubUrl: PropTypes.string,
	podcast: PropTypes.bool,
	year: PropTypes.number,
}

ContentImageListItem.defaultProps = {
	subtitle: '',
	notes: '',
	podcast: false,
	bookClubUrl: '',
	year: 2000,
}
