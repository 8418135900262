const offers =
	'interface design (UX/UI): visual design, digital product design, interaction design, hi-fi prototyping, design systems, brand design'

module.exports = {
	pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

	title: 'Richard Bruskowski', // Navigation and Site Title
	titleAlt: 'Richard Bruskowski, Freelance Designer', // Alternative Site title for SEO
	titleShort: 'Bruskowski', // short_name for manifest
	headline: 'Visual Design, Digital Products, Prototyping', // Headline for schema.org JSONLD
	siteUrl: 'https://bruskowski.design', // Domain of your site. No trailing slash!
	siteLanguage: 'en', // Language Tag on <html> element
	icon: 'src/icon.png', // used to generate favicons and app icons in different sizes
	banner: '/assets/sharing-thumbnail.jpg', // Used for SEO, social media and manifest
	description: 'Offering ' + offers + '.',
	author: 'Richard Bruskowski', // Author for schema.org JSONLD

	// don't let these paths be indexed by search engines
	excludeFromSearch: ['/legal', '/review/'],
	// don't include these paths in sitemap
	excludeFromSitemap: ['/legal', '/review/*', '/archive/*'],

	// siteFBAppID: '123456789', // Facebook App ID - Optional
	twitter: '@bruskowski', // Twitter Username
	facebook: '',
	ogLanguage: 'en_UK', // og:language

	// Manifest and Progress color
	themeColor: '#141414', // System bar background in Android
	backgroundColor: '#141414', // splash screen color

	// Default Theme
	mode: 'dark', // ['dark' | 'light']

	// Your information
	logo: '/logos/monogram.svg', // used in page
	name: 'Richard Bruskowski',
	profession: 'Designer',
	vat: 'DE327501868',
	mail: 'r@bruskowski.design',
	mailSubject: '?subject=Your%20Portfolio',
	phone: '+491778883912',
	phoneFormatted: '+49 177 888 39 12',
	socialMedia: [
		// Remember to import Icon in Header.js
		// {
		// 	url: 'https://dribbble.com/richy',
		// 	name: 'Dribbble',
		// 	rel: 'noopener noreferrer'
		// },
		{
			url: '/rss.xml',
			name: 'RSS',
			inFooter: true,
		},
		{
			url: 'https://mastodon.social/@richy',
			name: 'Mastodon',
			rel: 'me',
			inFooter: true,
		},
		{
			url: 'https://read.cv/bruskowski',
			name: 'Read.cv',
			rel: 'noopener noreferrer',
			inFooter: false,
		},
		// {
		// 	url: 'https://twitter.com/bruskowski',
		// 	name: 'Twitter',
		// 	rel: 'noopener noreferrer',
		// 	inFooter: false,
		// },
		// {
		// 	url: "https://www.instagram.com/rlchy",
		// 	name: "Instagram",
		// rel: 'noopener noreferrer'
		// },
	],
	offers: offers,
	available: true,
	availableMessage: 'Available for freelance work.',
	unavailableMessage: 'Available for freelance work from April onwards.',
	base: 'Stuttgart, Germany + Remote',
}
