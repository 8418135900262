import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useSwipeable } from 'react-swipeable'

const SlideshowWrapper = styled.div`
	width: ${props => props.width};
	aspect-ratio: ${props => props.aspectRatio};

	position: relative;
	margin: 6em 0 12em 0;

	& small {
		position: absolute;
		left: 0;
		bottom: -5em;
		opacity: 0.4;

		font-size: var(--fs-xxs);
		line-height: 150%;
		padding: 2em 2em 1em 0.5em;
		margin: 0 2em 1em 3vw;

		text-align: left;
		max-width: 36em;
	}
`

const PreviousHalf = styled.div`
	position: absolute;
	left: 0;
	right: 70%;
	top: 0;
	bottom: 0;

	cursor: url('/assets/cursor-previous.svg') 48 24, w-resize;
`

const NextHalf = styled.div`
	position: absolute;
	left: 70%;
	right: 0;
	top: 0;
	bottom: 0;

	cursor: url('/assets/cursor-next.svg') 48 24, e-resize;
`

const Slide = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	opacity: ${props => (props.isCurrent || props.isBackground ? 1 : 0)};
	transition: opacity 0.5s ease-in;

	& .gatsby-resp-image-figure {
		margin: 0;
	}

	& figcaption {
		opacity: ${props => (props.isCurrent ? 1 : 0)};
		transition: opacity 0.5s ease-in;
		margin: 1em 2em 4em 3vw;
	}
`

const Slideshow = ({ children, width, aspectRatio }) => {
	const [currentSlide, setCurrentSlide] = useState(0)

	const handlePrevious = e => {
		if (currentSlide > 0) {
			setCurrentSlide(currentSlide - 1)
		} else setCurrentSlide(children.length - 1)
	}

	const handleNext = e => {
		if (currentSlide < children.length - 1) {
			setCurrentSlide(currentSlide + 1)
		} else setCurrentSlide(0)
	}

	const config = {
		delta: 10, // min distance(px) before a swipe starts. *See Notes*
		preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
		trackTouch: true, // track touch input
		trackMouse: false, // track mouse input
		rotationAngle: 0, // set a rotation angle
		swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
		touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
	}

	const handlers = useSwipeable({
		onSwipedLeft: handleNext,
		onSwipedRight: handlePrevious,
		...config,
	})

	return (
		<SlideshowWrapper width={width} aspectRatio={aspectRatio} {...handlers}>
			{children.map((_, i) => (
				<Slide
					key={`slide-${i}`}
					isCurrent={i === currentSlide}
					isBackground={i === 0}
				>
					{_}
				</Slide>
			))}
			<PreviousHalf onClick={handlePrevious}></PreviousHalf>
			<NextHalf onClick={handleNext}></NextHalf>
			<small>{`${currentSlide + 1} / ${children.length}`}</small>
		</SlideshowWrapper>
	)
}
export default Slideshow

Slideshow.propTypes = {
	//sources: PropTypes.array,
	width: PropTypes.string,
	aspectRatio: PropTypes.string,
	//captions: PropTypes.array,
	children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

Slideshow.defaultProps = {
	//sources: [''],
	width: '100%',
	aspectRatio: '16 / 9',
	//captions: [''],
}
