import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Logo from './Logo'

const StyledTextImage = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: ${props => (props.reverseOrder ? 'row-reverse' : 'row')};
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 8vw;

	img {
		width: 100%;
		height: auto;
	}

	& > * {
		width: 45%;
		flex-grow: 1;
		max-width: ${props => props.maxWidth};
		min-width: ${props => props.minWidth};
		@media (min-width: ${props => props.theme.breakpoints.l}) {
			padding: 0 ${props => props.gap};
		}
	}
	& > .logo-wrapper {
		width: 100%;
		height: auto;
		flex-grow: 0;
		padding: 0;
		position: relative;
		margin-top: 0;

		.logo-position {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
		}

		@media (min-width: ${props => props.theme.breakpoints.s}) {
			width: 100%;
		}

		@media (min-width: ${props => props.theme.breakpoints.m}) {
			width: 80%;
		}

		@media (min-width: ${props => props.theme.breakpoints.ml}) {
			width: 70%;
		}

		@media (min-width: ${props => props.theme.breakpoints.l}) {
			width: 50%;
			margin-top: 4em;
			padding: 0 0.5em;
		}

		@media (min-width: ${props => props.theme.breakpoints.xl}) {
			width: 50%;
		}
	}
`

const ContentProcessGraph = ({
	children,
	color,
	texture,
	speed,
	pattern,
	...rest
}) => {
	return (
		<StyledTextImage {...rest}>
			{children[0]}

			<div className="logo-wrapper">
				{children[1]}
				<div className="logo-position">
					<Logo
						allowOrbitRotation
						isCentered
						width="70%"
						height="50%"
						speed={speed || 0.003}
						color={color}
						texture={texture}
					/>
				</div>
			</div>
		</StyledTextImage>
	)
}

export default ContentProcessGraph

ContentProcessGraph.propTypes = {
	responsiveWidth: PropTypes.array,
	maxWidth: PropTypes.string,
	minWidth: PropTypes.string,
	gap: PropTypes.string,
	reverseOrder: PropTypes.bool,
}

ContentProcessGraph.defaultProps = {
	responsiveWidth: ['100%', '100%', '100%', '100%', '45%', '45%'],
	maxWidth: '960px',
	minWidth: '300px',
	gap: '2em',
	reverseOrder: false,
}
