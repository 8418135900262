import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledContentGroup = styled.section`
	padding: ${props => props.padding} 0;
	margin: 0;
	background-color: ${props =>
		props.background ? 'rgba(var(--color-ink-rgb), 0.1)' : 'transparent'};

	h2,
	h3 {
		text-align: center;
		margin-bottom: 2em;
	}
`

const ContentGroup = ({ children, ...rest }) => {
	return <StyledContentGroup {...rest}>{children}</StyledContentGroup>
}

export default ContentGroup

ContentGroup.propTypes = {
	padding: PropTypes.string,
	background: PropTypes.bool,
}

ContentGroup.defaultProps = {
	padding: '4em',
	background: false,
}
