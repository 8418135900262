/** @jsx jsx */
import { Global, css, jsx, useTheme } from '@emotion/react'
import { parseToRgb } from 'polished'

const getRgbTriplet = color => {
	const c = parseToRgb(color)
	return `${c.red}, ${c.green}, ${c.blue}`
}

const makeGlobalStyles = (props, theme) => css`
	:root {
		--color-bg: ${props.backgroundGradient
			? `linear-gradient(
	to top,
	hsla(55, 100%, 50%, 1) 20%,
	hsla(55, 100%, 50%, 0) 50%
  ),
  radial-gradient(
	ellipse at 80% 45%,
	hsla(120, 100%, 65%, 0.15),
	hsla(60, 100%, 65%, 0) 50%
  ),
  radial-gradient(
	ellipse at 10% 60%,
	hsla(0, 100%, 65%, 0.2),
	hsla(60, 100%, 65%, 0) 50%
  ),
  radial-gradient(
	ellipse at top right,
	hsla(60, 100%, 65%, 1),
	hsla(60, 100%, 65%, 0) 50%
  ),
  radial-gradient(
	ellipse at bottom right,
	hsla(48, 100%, 65%, 1),
	hsla(80, 100%, 65%, 0) 50%
  ),
  radial-gradient(
	ellipse at bottom left,
	hsla(65, 100%, 65%, 1),
	hsla(40, 100%, 65%, 0) 50%
  ),
  radial-gradient(
	ellipse at top left,
	hsla(53, 97%, 65%, 1),
	hsla(53, 97%, 70%, 1)
  )`
			: props.background || theme.colors.bg};
		--color-bg-rgb: ${getRgbTriplet(props.background || theme.colors.bg)};
		--color-ink: ${theme.colors.ink};
		--color-ink-rgb: ${getRgbTriplet(theme.colors.ink)};
		--color-link: ${theme.colors.link};
		--color-link-rgb: ${getRgbTriplet(theme.colors.link)};
		--color-linkHover: ${theme.colors.linkHover};
		--color-linkHover-rgb: ${getRgbTriplet(theme.colors.linkHover)};
		--color-selection: ${theme.colors.selection};
		--color-selection-rgb: ${getRgbTriplet(theme.colors.selection)};
		--color-accent: ${theme.colors.accent};
		--color-accent-rgb: ${getRgbTriplet(theme.colors.accent)};
		--color-success: ${theme.colors.success};
		--color-success-rgb: ${getRgbTriplet(theme.colors.success)};
		--color-highlights-rgb: 255, 255, 255;
		--color-shadows-rgb: 0, 0, 0;

		--font-display: ${theme.fonts.display};
		--font-default: ${theme.fonts.default};

		--weight-display: ${theme.weights.display};
		--weight-default: ${theme.weights.default};
		--weight-eyebrow: ${theme.weights.eyebrow};
		--weight-subtle-title: ${theme.weights.subtleTitle};
		--weight-title: ${theme.weights.title};
		--weight-action: ${theme.weights.action};

		--opacity-eyebrow: ${theme.opacities.eyebrow};

		--ls-eyebrow: ${theme.letterSpacings.eyebrow}em;
		--ls-title: ${theme.letterSpacings.title}em;

		--line-height-headlines: ${theme.lineHeights.headlines};
		--line-height-display: ${theme.lineHeights.display};

		--fs-base: 16px;
		--fs-xxxs: 10px;
		--fs-xxs: 12px;
		--fs-xs: 0.75rem;
		--fs-s: 0.9rem;
		--fs-m: 1rem;
		--fs-ml: 1.125rem;
		--fs-l: 1.125rem;
		--fs-xl: 1.266rem;
		--fs-xxl: 1.424rem;
		--fs-xxxl: 1.602rem;
		--fs-4xl: 2rem;
		--fs-intro: calc(1.3rem + 8vw);
		--fs-hero: 4rem;
	}

	@media screen and (min-width: ${theme.breakpoints.xs}) {
		:root {
			--fs-4xl: 2.25rem;
			--fs-intro: calc(1.8rem + 7.4vw);
			--fs-hero: 5rem;
		}
	}

	@media screen and (min-width: ${theme.breakpoints.s}) {
		:root {
			--fs-base: 18px;
			--fs-4xl: 2.5rem;
			--fs-intro: calc(2rem + 7.2vw);
			--fs-hero: 6rem;
		}
	}

	@media screen and (min-width: ${theme.breakpoints.m}) {
		:root {
			--fs-base: 19px;
			--fs-xxxs: 11px;
			--fs-xxs: 12px;
			--fs-xs: 0.75rem;
			--fs-s: 0.9rem;
			--fs-m: 1rem;
			--fs-l: 1.2rem;
			--fs-xl: 1.44rem;
			--fs-xxl: 1.728rem;
			--fs-xxxl: 2.074rem;
			--fs-4xl: 3rem;
			--fs-intro: calc(3rem + 4.6vw);
			--fs-hero: 7rem;
		}
	}

	@media screen and (min-width: ${theme.breakpoints.ml}) {
		:root {
			--fs-intro: calc(5.2rem + 2vw);
		}
	}

	@media screen and (min-width: ${theme.breakpoints.l}) {
		:root {
			--fs-base: 20px;
			--fs-xxxs: 11px;
			--fs-xxs: 13px;
			--fs-xs: 0.75rem;
			--fs-s: 0.9rem;
			--fs-m: 1rem;
			--fs-l: 1.25rem;
			--fs-xl: 1.563rem;
			--fs-xxl: 1.953rem;
			--fs-xxxl: 2.441rem;
			--fs-4xl: 3.5rem;
			--fs-intro: calc(5.6rem + 2vw);
		}
	}

	@media screen and (min-width: ${theme.breakpoints.xl}) {
		:root {
			--fs-4xl: 4rem;
			--fs-intro: calc(5.4rem + 2.8vw);
		}
	}

	html {
		font-size: var(--fs-base);

		scroll-behavior: smooth;

		h1 {
			font-size: var(--fs-xxxl);
			font-family: var(--font-display);
		}
		h2 {
			font-size: var(--fs-xxl);
		}
		h3 {
			font-size: var(--fs-xl);
		}
		h4 {
			font-size: var(--fs-l);
		}
		h5 {
			font-size: var(--fs-m);
		}
	}

	@-moz-document url-prefix() {
		html {
			scroll-behavior: auto;
		}
	}

	body {
		color: var(--color-ink);
		background-color: var(--color-bg, #141414);
	}
	::selection {
		color: var(--color-ink);
		background-color: var(--color-selection);
	}

	abbr {
		border-bottom: 1px dashed rgba(var(--color-ink-rgb), 0.6);
		text-decoration: none;
	}

	a {
		color: var(--color-link);
		transition: color 0.3s ease-in-out;

		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-linkHover);
		}
	}
	a:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
		}
		&:focus {
			outline: none;
		}
	}

	@keyframes slideleft {
		from {
			background-position-x: -200px;
		}
		to {
			background-position-x: -45000px;
		}
	}

	a {
		color: var(--color-link);
		background-image: url('/assets/wavy-underline.png');
		background-repeat: repeat-x;
		background-position: -200px 100%;
		background-size: 115px;
		padding-bottom: 2px;

		&:hover,
		&:focus {
			color: var(--color-link);
			animation: slideleft 1800s infinite linear;
			outline: none;
		}

		&:focus-visible {
			outline: 2px solid currentColor;
			outline-offset: 0.25em;
		}
	}

	a.no-underline {
		background-image: none;

		color: var(--color-link);
		transition: color 0.3s ease-in-out;

		&:hover,
		&:focus {
			color: var(--color-linkHover);
		}

		&:focus {
			outline: none;
		}

		&:focus-visible {
			outline: 2px solid currentColor;
			outline-offset: 0.25em;
		}
	}

	a.focus-underline {
		&:focus {
			outline: none;
		}

		&:focus-visible {
			box-shadow: 0 2px 0 0 currentColor;
		}
	}

	a.shine {
		color: var(--color-link);
		background: linear-gradient(
			to top,
			rgba(var(--color-link-rgb), 1),
			rgba(var(--color-link-rgb), 0)
		);
		background-repeat: repeat-x;
		background-position: -200px 100%;
		background-size: 1px 2px;
		padding-bottom: 2px;
		transition: background-size 0.5s linear;

		&:hover,
		&:focus {
			background-size: 1px 8px;
		}
	}

	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		font-family: var(--font-default);
		font-feature-settings: 'salt', 'ss01', 'ss02';
		font-variant-ligatures: discretionary-ligatures;
		font-variant-numeric: tabular-nums;
		font-weight: var(--weight-default);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: var(--weight-default);
		line-height: var(--line-height-headlines);
	}

	h3 {
		font-family: var(--font-display);
		font-weight: var(--weight-display);
	}

	.gatsby-resp-image-wrapper {
		margin: 1rem 0;
	}

	canvas:focus {
		outline: none;
	}

	figcaption {
		font-size: var(--fs-xs);
		line-height: 150%;
		padding: 2em 2em 3em 0.5em;
		margin: 0 2em 4em 3vw;
		opacity: 0.8;
		text-align: left;
		max-width: 36em;
	}

	.number {
		letter-spacing: 0.0625em;
	}
`

const GlobalStyle = props => {
	const theme = useTheme()

	return <Global styles={makeGlobalStyles(props, theme)} />
}

export default GlobalStyle
