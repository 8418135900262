/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { LayoutGroup, AnimatePresence, motion } from 'framer-motion'
import data from '../../content/vita.yaml'

import { Button } from './'

const Column = styled.div`
	flex-grow: 1;
	flex-basis: 100%;
	margin: 0 0 2em 0;
	font-size: var(--fs-s);

	@media (min-width: ${props => props.theme.breakpoints.s}) {
		flex-basis: ${props => props.baseWidth[0]};
		margin: 0 3em 2em 0;
		:last-of-type {
			margin: 0;
		}
		font-size: var(--fs-s);
	}
	@media (min-width: ${props => props.theme.breakpoints.m}) {
		flex-basis: ${props => props.baseWidth[1]};
		margin: 0 4em 2em 0;
		:last-of-type {
			margin: 0;
		}
		font-size: var(--fs-xs);
	}

	& h2 {
		margin: 0;
		font-weight: var(--weight-eyebrow);
		opacity: var(--opacity-eyebrow);
		letter-spacing: var(--ls-eyebrow);
	}

	& ul {
		padding: 0;
	}
	& ul li {
		list-style-type: none;
		padding: 0;
		line-height: 150%;
		cursor: pointer;
	}

	& ul li a {
		display: inline-block;
		padding-top: 0.75em;
	}

	& ul li a.small {
		padding-top: 0;
		display: inline-block;
	}

	& button.summary {
		color: inherit;
		background-color: transparent;
		padding: 0.125em 0 0.375em 0;
		border: none;
		font-family: var(--font-default);
		font-size: 1.5em;
		font-weight: var(--weight-action);
		line-height: 1.25;
		margin: 0;
		display: block;
		width: 100%;
		text-align: left;
		transition: opacity 0.3s linear, box-shadow 0.3s linear,
			transform 0.3s ease-out;
	}

	& button.summary:hover {
		opacity: 0.8;
	}

	& button.summary:active {
		opacity: 0.6;
		transform: translateY(1px);
	}
`

const AwardsList = styled.ul`
	padding: 0;
	margin-bottom: 1em;
`

const AwardsListItem = styled.li`
	margin-bottom: 1em;
`

const DetailWrapper = styled(motion.div)`
	max-width: 18em;
	padding-left: 1.25em;
	position: relative;

	&:before {
		content: '';
		height: 100%;
		position: absolute;
		top: 0.375em;
		left: 0.125em;
		width: 1px;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAAaADAAQAAAABAAAABAAAAAAxwhKOAAAAFElEQVQIHWNgAAEREZH/TCAGmAAADjMBQBe/3rsAAAAASUVORK5CYII='),
			linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.6),
				rgba(255, 255, 255, 0.4),
				rgba(255, 255, 255, 0)
			);
	}
`

const Details = ({ summary, children }) => {
	const [isOpen, setOpen] = React.useState(false)

	const toggle = () => {
		setOpen(!isOpen)
	}

	const details = {
		hidden: {
			opacity: 0,
			height: 0,
			transition: {
				opacity: { duration: 0.4, ease: 'easeOut' },
				height: {
					type: 'spring',
					damping: 40,
					mass: 1.8,
					stiffness: 240,
					delay: 0.05,
				},
			},
		},
		show: {
			opacity: 1,
			height: 'auto',
			transition: {
				opacity: { duration: 0.2, ease: 'easeIn', delay: 0.05 },
				height: {
					type: 'spring',
					damping: 40,
					mass: 1.8,
					stiffness: 260,
				},
			},
		},
	}

	return (
		<li>
			<button onClick={toggle} className="summary">
				{summary}
			</button>
			<AnimatePresence>
				{isOpen && (
					<DetailWrapper
						layout
						key="details"
						variants={details}
						initial="hidden"
						animate="show"
						exit="hidden"
					>
						{children}
						<br />
						<br />
					</DetailWrapper>
				)}
			</AnimatePresence>
		</li>
	)
}

const TruncateList = ({ max, children }) => {
	const [isOpen, setOpen] = React.useState(false)

	const toggle = () => {
		setOpen(!isOpen)
	}

	const container = {
		hidden: {
			height: 0,
			transition: {
				staggerChildren: 0.06,

				staggerDirection: -1,
				delay: 0.25,
				type: 'spring',
				damping: 45,
				mass: 2,
				stiffness: 120,
			},
		},
		show: {
			height: 'auto',
			transition: {
				staggerChildren: 0.06,

				type: 'spring',
				damping: 45,
				mass: 2,
				stiffness: 260,
			},
		},
	}

	const item = {
		hidden: { opacity: 0 },
		show: { opacity: 1 },
	}

	return max < children.length ? (
		<>
			{children.slice(0, max)}
			<AnimatePresence>
				{isOpen && (
					<motion.div
						key="truncated"
						variants={container}
						initial="hidden"
						animate="show"
						exit="hidden"
					>
						{children.slice(max, children.length).map(_ => (
							<motion.div variants={item}>{_}</motion.div>
						))}
					</motion.div>
				)}
			</AnimatePresence>
			<li>
				<Button onClick={toggle}>{isOpen ? 'Less' : 'More'}</Button>
			</li>
		</>
	) : (
		<>{children}</>
	)
}

const Vita = () => {
	return (
		<>
			{Object.keys(data).map(key => (
				<Column baseWidth={data[key].width} key={key}>
					<LayoutGroup>
						<h2 id={key}>{data[key].title}</h2>
						<motion.ul layout>
							<TruncateList max={data[key].truncate}>
								{data[key].items.map((_, i) => (
									<Details
										key={`${key}-${i}`}
										summary={_.summary}
									>
										{_.time && (
											<>
												{_.time}
												<br />
											</>
										)}
										{_.description &&
											_.description.map((row, index) => (
												<React.Fragment key={index}>
													{row}
													<br />
												</React.Fragment>
											))}
										{_.link && (
											<>
												<a href={_.link.url}>
													{_.link.label}
												</a>
											</>
										)}
										{_.items && (
											<AwardsList>
												{_.items.map((item, i1) => (
													<AwardsListItem key={i1}>
														{item.description &&
															item.description.map(
																(row, i2) => (
																	<>
																		{row}
																		<br />
																	</>
																)
															)}
														{item.link && (
															<>
																<a
																	className="small"
																	href={
																		item
																			.link
																			.url
																	}
																>
																	{
																		item
																			.link
																			.label
																	}
																</a>
															</>
														)}
													</AwardsListItem>
												))}
											</AwardsList>
										)}
									</Details>
								))}
							</TruncateList>
						</motion.ul>
					</LayoutGroup>
				</Column>
			))}
		</>
	)
}

export default Vita
