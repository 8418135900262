import React from 'react'

function Blog(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" stroke="currentColor" fillRule="evenodd">
				<path
					strokeLinejoin="round"
					d="M5 8V5h14v3M5 13v-3h14v3M5 15h14v4H5z"
				/>
			</g>
		</svg>
	)
}

const MemoBlog = React.memo(Blog)
export default MemoBlog
