import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledContentGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(${props => props.cols[0]}, 1fr);
	grid-gap: ${props => props.gap};
	max-width: ${props => props.maxWidth};
	margin: ${props => props.margin} auto;

	& > video,
	& video,
	& > .gatsby-image-wrapper,
	& > iframe {
		margin: 0 !important;
	}

	@media (min-width: ${props => props.theme.breakpoints.s}) {
		grid-template-columns: repeat(${props => props.cols[1]}, 1fr);
	}

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		grid-template-columns: repeat(${props => props.cols[2]}, 1fr);
	}

	@media (min-width: ${props => props.theme.breakpoints.ml}) {
		grid-template-columns: repeat(${props => props.cols[3]}, 1fr);
	}

	@media (min-width: ${props => props.theme.breakpoints.l}) {
		grid-template-columns: repeat(${props => props.cols[4]}, 1fr);
	}

	@media (min-width: ${props => props.theme.breakpoints.xl}) {
		grid-template-columns: repeat(${props => props.cols[5]}, 1fr);
	}

	@media (max-width: ${props => props.theme.breakpoints.m}) {
		& > * {
			align-self: center !important;
			justify-self: center !important;
		}
	}
`

const ContentGrid = ({ children, ...rest }) => {
	return <StyledContentGrid {...rest}>{children}</StyledContentGrid>
}

export default ContentGrid

ContentGrid.propTypes = {
	maxWidth: PropTypes.string,
	gap: PropTypes.string,
	margin: PropTypes.string,
	cols: PropTypes.array,
}

ContentGrid.defaultProps = {
	maxWidth: 'none',
	gap: '1em',
	margin: '4em',
	cols: [1, 1, 2, 2, 3, 3],
}
