import React from 'react'

function ArrowRight(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M17.02 16L21 11.911 17.02 8M21 11.911H3"
				stroke="currentColor"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			/>
		</svg>
	)
}

const MemoArrowRight = React.memo(ArrowRight)
export default MemoArrowRight
