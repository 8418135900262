import React from 'react'

function SeekBack(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				stroke="currentColor"
			>
				<path d="M18.466 19.136A7.5 7.5 0 0013 6.5H5.5l4-3.5" />
				<path
					strokeLinejoin="round"
					d="M13.345 11h-3.39l-.688 3.97c.735-.87 1.56-1.264 2.476-1.182C13.116 13.91 14 15.05 14 16.438c0 1.39-.983 2.562-2.484 2.562-1.5 0-2.376-.983-2.516-1.942"
				/>
			</g>
		</svg>
	)
}

const MemoSeekBack = React.memo(SeekBack)
export default MemoSeekBack
