import React from 'react'

function Play(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				d="M6 19.75L20.5 12 6 4.25z"
			/>
		</svg>
	)
}

const MemoPlay = React.memo(Play)
export default MemoPlay
