/* eslint no-unused-expressions: off */
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { IconClose, IconUpwards } from './Icons'

const ESCAPE_KEYS = ['27', 'Escape']

const Wrapper = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 500;
	pointer-events: none;
	/* mix-blend-mode: difference;
	background: none; */

	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	@media (min-width: ${props => props.theme.breakpoints.m}) {
		justify-content: flex-end;
	}
`

const Back = styled(Link)`
	height: auto;
	padding: 1em;
	pointer-events: auto;

	& > svg {
		stroke-width: 0.75;
		color: var(--color-ink);
		width: 2em;
		height: 2em;
		transition: transform ease-out 0.3s;
		transform: scale(1);
	}
	&:hover > svg {
		transform: scale(0.9);
	}

	&:hover > svg {
		transform: scale(0.8);
	}
`

const Header = ({ arrow, isImpressum, returnTo, collection, ...props }) => {
	let upwards = '/'
	switch (collection) {
		case 'featured':
			upwards = '/projects'
			break
		case 'side':
			upwards = '/blog'
			break
		default:
			break
	}

	React.useEffect(() => {
		function handler({ key }) {
			if (ESCAPE_KEYS.includes(String(key))) {
				navigate(upwards)
			}
		}
		document.addEventListener('keydown', handler, false)
		return () => {
			document.removeEventListener('keydown', handler, false)
		}
	}, [upwards])

	return (
		<Wrapper>
			{arrow && (
				<Back
					className="no-underline"
					aria-label={isImpressum ? 'Close' : 'Upwards'}
					to={isImpressum ? returnTo : upwards}
				>
					{isImpressum ? <IconClose /> : <IconUpwards />}
				</Back>
			)}
		</Wrapper>
	)
}

export default Header

Header.propTypes = {
	collection: PropTypes.string,
	isImpressum: PropTypes.bool,
	arrow: PropTypes.bool,
	returnTo: PropTypes.string,
}

Header.defaultProps = {
	arrow: false,
	collection: '',
	isImpressum: false,
	returnTo: '',
}
