import styled from '@emotion/styled'

const Button = styled.button`
	color: var(--color-ink);
	font-family: var(--font-default);
	font-weight: var(--weight-action);
	line-height: 1;
	vertical-align: middle;
	display: inline-flex;
	align-items: center;
	font-size: var(--fs-xs);
	margin-top: 2em;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(var(--color-ink-rgb), 0.8);
	border-radius: 2em;
	padding: 0.5em 1em 0.5em 1em;
	opacity: 0.5;
	background: none;
	background-color: rgba(var(--color-ink-rgb), 0);
	transition: opacity 0.3s linear, box-shadow 0.3s linear,
		transform 0.3s ease-out;
	box-shadow: inset 0 0 0 1.5px rgba(var(--color-ink-rgb), 0);
	:hover {
		opacity: 0.8;
		background-color: rgba(var(--color-ink-rgb), 0.07);
	}
	:focus {
		outline: none;
	}
	:focus-visible {
		outline: 2px solid transparent;
		opacity: 0.8;
		background-color: rgba(var(--color-ink-rgb), 0.07);
		box-shadow: inset 0 0 0 1.5px rgba(var(--color-ink-rgb), 1);
	}
	:active {
		opacity: 0.6;
		transform: translateY(1px);
		background-color: rgba(var(--color-ink-rgb), 0.07);
	}
`

export default Button
