import React from 'react'

function About(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke="currentColor" fill="none" fillRule="evenodd">
				<circle cx={12} cy={9} r={2} />
				<circle cx={12} cy={12} r={8} />
				<path
					d="M6 17a3 3 0 013-3h6a3 3 0 013 3h0"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}

const MemoAbout = React.memo(About)
export default MemoAbout
