import React from 'react'

function SeekForward(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				stroke="currentColor"
			>
				<path d="M5.534 19.136A7.5 7.5 0 0111 6.5h7.5L14 3" />
				<path
					strokeLinejoin="round"
					d="M14.345 11h-3.39l-.688 3.97c.735-.87 1.56-1.264 2.476-1.182C14.116 13.91 15 15.05 15 16.438c0 1.39-.983 2.562-2.484 2.562-1.5 0-2.376-.983-2.516-1.942"
				/>
			</g>
		</svg>
	)
}

const MemoSeekForward = React.memo(SeekForward)
export default MemoSeekForward
