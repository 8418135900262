import React from 'react'

function Projects(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke="currentColor" fill="none">
				<path d="M5 5h14v14H5z" />
				<path d="M5 5h14v12H5z" />
			</g>
		</svg>
	)
}

const MemoProjects = React.memo(Projects)
export default MemoProjects
