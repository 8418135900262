import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledTextImage = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: ${props => (props.reverseOrder ? 'row-reverse' : 'row')};
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 8vw;

	& > * {
		width: 45%;
		flex-grow: 1;
		max-width: ${props => props.maxWidth};
		min-width: ${props => props.minWidth};
		@media (min-width: ${props => props.theme.breakpoints.l}) {
			padding: 0 ${props => props.gap};
		}
	}
	& > .gatsby-resp-image-wrapper,
	& > img {
		width: 100%;
		height: auto;
		flex-grow: 0;
		padding: 0;
		margin: 4em auto 4em auto;

		@media (min-width: ${props => props.theme.breakpoints.s}) {
			width: 100%;
		}

		@media (min-width: ${props => props.theme.breakpoints.m}) {
			width: 80%;
		}

		@media (min-width: ${props => props.theme.breakpoints.ml}) {
			width: 70%;
		}

		@media (min-width: ${props => props.theme.breakpoints.l}) {
			width: 50%;
			padding: 0 0.5em;
		}

		@media (min-width: ${props => props.theme.breakpoints.xl}) {
			width: 50%;
		}
	}
`

const ContentTextImage = ({ children, ...rest }) => {
	return <StyledTextImage {...rest}>{children}</StyledTextImage>
}

export default ContentTextImage

ContentTextImage.propTypes = {
	responsiveWidth: PropTypes.array,
	maxWidth: PropTypes.string,
	minWidth: PropTypes.string,
	gap: PropTypes.string,
	reverseOrder: PropTypes.bool,
}

ContentTextImage.defaultProps = {
	responsiveWidth: ['100%', '100%', '100%', '100%', '45%', '45%'],
	maxWidth: '960px',
	minWidth: '300px',
	gap: '2em',
	reverseOrder: false,
}
