import React, { Suspense } from 'react'
import styled from '@emotion/styled'

import LogoImage from './LogoImage'

//import useOnScreen from '../utils/useOnScreen'

const Logo3D = React.lazy(() => import('./Logo3D'))

const LogoCentered = styled.div`
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		25% {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	animation: fadeIn 1s linear;
`

const FadeIn = styled.div`
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		50% {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	animation: fadeIn 1s linear;
`

const Logo = props => {
	const ref = React.useRef(null)
	const isSSR = typeof window === 'undefined'
	const onScreen = true // useOnScreen(ref, '600px') // test performance

	const webglSupport = React.useMemo(() => {
		try {
			var canvas = document.createElement('canvas')
			return (
				!!window.WebGLRenderingContext &&
				(canvas.getContext('webgl') ||
					canvas.getContext('experimental-webgl'))
			)
		} catch (e) {
			return false
		}
	}, [])

	return (
		<LogoCentered
			ref={ref}
			className="logo"
			style={{ width: props.width, height: props.height }}
		>
			{isSSR ? (
				<LogoImage {...props} />
			) : (
				<Suspense fallback={<LogoImage {...props} />}>
					{webglSupport && onScreen && (
						<FadeIn style={{ width: '100%', height: '100%' }}>
							<Logo3D {...props} />
						</FadeIn>
					)}
				</Suspense>
			)}
		</LogoCentered>
	)
}

export default Logo
