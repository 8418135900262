import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	margin: ${p => '-' + p.gap};
	align-items: flex-start;
	& > * {
		width: ${p => p.width[0]};

		@media (min-width: ${props => props.theme.breakpoints.s}) {
			width: ${p => p.width[1]};
		}

		@media (min-width: ${props => props.theme.breakpoints.m}) {
			width: ${p => p.width[2]};
		}

		@media (min-width: ${props => props.theme.breakpoints.ml}) {
			width: ${p => p.width[3]};
		}

		@media (min-width: ${props => props.theme.breakpoints.l}) {
			width: ${p => p.width[4]};
		}

		@media (min-width: ${props => props.theme.breakpoints.xl}) {
			width: ${p => p.width[5]};
		}

		max-width: ${p => p.maxWidth};
		min-width: ${p => p.minWidth};
		margin: ${p => p.gap};
	}
`

const ContentWrapper = ({ children, ...rest }) => {
	return <StyledWrapper {...rest}>{children}</StyledWrapper>
}

export default ContentWrapper

ContentWrapper.propTypes = {
	width: PropTypes.array,
	maxWidth: PropTypes.string,
	minWidth: PropTypes.string,
	gap: PropTypes.string,
}

ContentWrapper.defaultProps = {
	width: ['100%', '100%', '60%', '55%', '50%', '45%'],
	maxWidth: '960px',
	minWidth: '300px',
	gap: '1em',
}
