import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const Transition = props => {
	const duration = 0.3

	const variants = {
		initial: {
			opacity: 0,
			y: 20,
		},
		enter: {
			opacity: 1,
			y: 0,
			transition: {
				duration: duration,
				when: 'beforeChildren',
				y: {
					type: 'spring',
					mass: 1,
					stiffness: 60,
					damping: 20,
				},
			},
		},
		exit: {
			opacity: 0,
			y: -20,
			transition: { duration: duration },
		},
	}

	return (
		<AnimatePresence mode="wait">
			<motion.div
				key={props.location.pathname}
				variants={variants}
				initial="initial"
				animate="enter"
				exit="exit"
			>
				{props.children}
			</motion.div>
		</AnimatePresence>
	)
}

export default Transition
