import React from 'react'

function Process(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" stroke="currentColor" fillRule="evenodd">
				<path
					d="M13 9l3.95-1.95A7 7 0 006.106 15.78M11 15l-3.95 1.95a7 7 0 0011.045-8.396"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}

const MemoProcess = React.memo(Process)
export default MemoProcess
