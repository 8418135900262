import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

function useDocumentScrollThrottled(callback) {
	const [scrollPosition, setScrollPosition] = useState(0)
	let previousScrollTop = 0

	function handleDocumentScroll() {
		const {
			scrollTop: currentScrollTop,
			scrollHeight: currentScrollHeight,
		} = document.documentElement || document.body

		setScrollPosition(previousPosition => {
			previousScrollTop = previousPosition

			return currentScrollTop
		})

		callback({
			previousScrollTop,
			currentScrollTop,
			currentScrollHeight,
		})
	}

	const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)

	useEffect(() => {
		window.addEventListener('scroll', handleDocumentScrollThrottled)

		return () =>
			window.removeEventListener('scroll', handleDocumentScrollThrottled)
	}, [])
}

export default useDocumentScrollThrottled
