import React from 'react'

function Pause(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				stroke="currentColor"
			>
				<path d="M6 19.75h4.5V4.25H6zM14 19.75h4.5V4.25H14z" />
			</g>
		</svg>
	)
}

const MemoPause = React.memo(Pause)
export default MemoPause
