import React from 'react'

function Contact(props) {
	return (
		<svg
			className="icon"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none" fillRule="evenodd" stroke="currentColor">
				<path strokeLinejoin="round" d="M20 6v11h-4v3l-3-3H4V6h16z" />
				<path d="M4 6l8 6 8-6" />
			</g>
		</svg>
	)
}

const MemoContact = React.memo(Contact)
export default MemoContact
